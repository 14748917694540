import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <blockquote>
      <p parentName="blockquote">{`All Diploma students were involved in three areas of the CAS Programme.`}</p>
    </blockquote>
    <p>{`This year they participated in sustained, collaborative projects such as Kindergarten building, Animal shelter, Visually impaired centre support, Mural, Djerela, Kindergarten English Teaching, CAS Media, CoronaKindness and Children of the World to Children of Ukraine projects, Music band, and ran a Tutoring Centre. They also initiated new projects such as Covid4Climate, VideoEd and Kaleidoscope. Students contributed to school publications, created educational  videos that were used in live classes for local kids.`}</p>
    <p>{`In cooperation with the PTA students also organised a schoolwide Zoom-a-Thon fundraising event. All projects were modified in correspondence with the safety rules and regulations.  Students set challenging goals, planned their activities, developed leadership qualities and  learned new skills. Students  also were involved in school sports programme and individual sports activities. CAS students also participated in the CEESA Service student Council.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      